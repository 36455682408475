<template>
  <div>
    <vs-card id="card">
      <div slot="header">
        <h3>Stocktake</h3>
      </div>
      <div class="flex flex-row">
        <div :class="`w-full mb-10 hidden sm:block`">
          <complianceCheck @fetchStocktake="fetchClinicDrugBookProduct" :isComplianceCheck="!isComplianceCheck"
            :complianceDrugbook="complianceDrugbook" :maxHeight="'75vh'" />
        </div>
        <div v-if="!isStarted" class="sm:hidden w-full">
          <!--Mobile Responsive Display for Product Table -->

          <div class="customTable" id="hideScrollBar">
            <vs-table ref="table" :sst="true" :total="100" :data="complianceDrugbook" @selected="handleClickProduct">
              <!-- v-if="complianceDrugbook.length" -->
              <template slot="thead">
                <vs-th sort-key="clinicName">PRODUCT NAME</vs-th>
                <vs-th class="countedQuantity" style="text-align: right" sort-key="orderMonth">COUNTED QUANTITY</vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="`data-${indextr}`" v-for="(tr, indextr) in data">
                  <vs-td :data="tr.productName">{{ tr.productName }}</vs-td>
                  <vs-td class="text-right" :data="tr.countedQuality">
                    <span>
                      {{
                          `${tr.countedQuality > 0 ? tr.countedQuality : "..."}`
                      }}
                    </span>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
        <!-- Mobile Display for side Panel -->
        <div v-if="isStarted" :class="`w-full ${isStarted ? 'sm:hidden' : ''}`">
          <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col justify-between"
            style="min-height: 90vh">
            <div class="flex flex-col">
              <div class="flex items-center justify-between">
                <p class="font-semibold text-xl customNameColor">
                  {{ selectedDrugProduct.productName }}
                </p>
                <vs-icon icon="close" @click="isStarted = !isStarted" class="cursor-pointer"></vs-icon>
              </div>
              <p class="py-2">
                {{ `${selectedDrugProduct.unit}` }}
              </p>
              <p class="py-2">Counted Quantity</p>
              <div class="w-full flex flex-row gap-3">
                <div
                  class="w-full flex flex-row increment-buttons rounded-sm pt-0 py-0 border-solid border border-gray-300">
                  <div class="customFlex flex justify-center items-center text-4xl">
                    <div @click="lessQuantity" class="cursor-pointer">-</div>
                  </div>
                  <div class="customFlex flex justify-center items-center text-lg">
                    <input type="number" class="text-center text-lg border-0 bg-transparent w-full"
                      v-model.number="selectedDrugProduct.countedQuality" />
                  </div>
                  <div class="customFlex flex justify-center items-center text-4xl">
                    <div @click="addQuantity" class="cursor-pointer">+</div>
                  </div>
                </div>
                <div class="customFlex flex justify-center items-center text-4xl">
                  <p class="py-2 text-sm font-medium leading-6 not-italic inline-block" style="color: #000000">
                    {{
                        `${selectedDrugProduct.unit.toLowerCase() === "ml"
                          ? "ml"
                          : "u"
                        }`
                    }}
                  </p>
                </div>
              </div>
              <vs-divider></vs-divider>
              <div style="display: flex; justify-content: space-between">
                <p class="py-2 text-sm font-medium leading-6 not-italic inline-block" style="color: #000000">
                  Drug Balance
                </p>
                <p class="py-2 text-sm font-medium leading-6 not-italic inline-block" style="color: #000000">
                  {{
                      `${selectedDrugProduct.drugBookBalance} ${selectedDrugProduct.unit.toLowerCase() === "ml"
                        ? "ml"
                        : "u"
                      }`
                  }}
                </p>
              </div>
              <div style="display: flex; justify-content: space-between">
                <p class="py-2 text-sm font-medium leading-6 not-italic inline-block" style="color: #ea5455">
                  Variance
                </p>
                <p class="py-2 text-sm font-medium leading-6 not-italic inline-block" style="color: #ea5455">
                  {{
                      selectedDrugProduct.drugBookBalance >
                        selectedDrugProduct.countedQuality
                        ? `-${selectedDrugProduct.drugBookBalance -
                        selectedDrugProduct.countedQuality
                        }`
                        : `+${Math.abs(
                          selectedDrugProduct.drugBookBalance -
                          selectedDrugProduct.countedQuality
                        )}`
                  }}
                  {{
                      `${selectedDrugProduct.unit.toLowerCase() === "ml"
                        ? "ml"
                        : "u"
                      }`
                  }}
                </p>
              </div>
              <vs-divider></vs-divider>
              <p class="py-2">Notes</p>
              <div class="w-full flex flex-col increment-buttons rounded-bl rounded-big-screen pt-0 py-0"
                style="border">
                <vs-textarea id="notesHeight" :class="
                  errors.includes('notes') && isValidating
                    ? 'customTextError'
                    : ''
                " class="py-0 my-0 mb-2" name="notes" v-model="selectedDrugProduct.notes" height="150px" />
                <div v-if="errors.includes('notes') && isValidating"
                  class="con-text-validation span-text-validation-danger vs-input--text-validation-span text-xs">
                  <span class="span-text-validation">Notes are mandatory for products with variances</span>
                </div>
              </div>
            </div>
            <div class="flex flex-col items-center justify-center">
              <p @click="previousProduct" class="pb-4 cursor-pointer customNameColor text-xs font-medium">
                Previous Product
              </p>
              <button @click="nextProduct"
                class="py-4 w-full customeBorderColor cursor-pointer customNameColor font-medium text-base bg-transparent fontn rounded-lg">
                {{
                    `Save${currentIndex + 1 != complianceDrugbook.length
                      ? ", next product"
                      : ""
                    }`
                }}
              </button>
            </div>
          </div>
        </div>
        <!-- Web Display for side Panel -->
        <div v-if="!isComplianceCheck && complianceDrugbook.length > 0" class="w-1/4 hidden sm:block">
          <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col justify-between"
            style="min-height: 90vh">
            <div class="flex flex-col">
              <p class="font-semibold text-xl customNameColor">
                {{ selectedDrugProduct.productName }}
              </p>
              <p class="py-2">
                {{ `${selectedDrugProduct.unit}` }}
              </p>
              <p class="py-2">Counted Quantity</p>
              <div class="w-full flex flex-row gap-3">
                <div class="w-full flex flex-row increment-buttons rounded-big-screen pt-0 py-0 border-solid">
                  <div class="customFlex flex justify-center items-center text-4xl">
                    <div @click="lessQuantity" class="cursor-pointer">-</div>
                  </div>
                  <div class="customFlex flex justify-center items-center text-lg">
                    <input type="number" class="text-center text-lg border-0 bg-transparent w-full"
                      v-model.number="selectedDrugProduct.countedQuality" />
                  </div>
                  <div class="customFlex flex justify-center items-center text-4xl">
                    <div @click="addQuantity" class="cursor-pointer">+</div>
                  </div>
                </div>

                <div class="customFlex flex justify-center items-center text-4xl">
                  <p class="py-2 text-sm font-medium leading-6 not-italic inline-block" style="color: #000000">
                    {{
                        `${selectedDrugProduct.unit.toLowerCase() === "ml"
                          ? "ml"
                          : "u"
                        }`
                    }}
                  </p>
                </div>
              </div>
              <vs-divider></vs-divider>
              <div style="display: flex; justify-content: space-between">
                <p class="py-2 text-sm font-medium leading-6 not-italic inline-block" style="color: #000000">
                  Drug Balance
                </p>
                <p class="py-2 text-sm font-medium leading-6 not-italic inline-block" style="color: #000000">
                  {{
                      `${selectedDrugProduct.drugBookBalance} ${selectedDrugProduct.unit.toLowerCase() === "ml"
                        ? "ml"
                        : "u"
                      }`
                  }}
                </p>
              </div>
              <div style="display: flex; justify-content: space-between">
                <p class="py-2 text-sm font-medium leading-6 not-italic inline-block" style="color: #ea5455">
                  Variance
                </p>
                <p class="py-2 text-sm font-medium leading-6 not-italic inline-block" style="color: #ea5455" v-if="
                  selectedDrugProduct.drugBookBalance -
                  selectedDrugProduct.countedQuality ==
                  0
                ">
                  {{
                      `${selectedDrugProduct.drugBookBalance -
                      selectedDrugProduct.countedQuality
                      }`
                  }}
                  {{
                      `${selectedDrugProduct.unit.toLowerCase() === "ml"
                        ? "ml"
                        : "u"
                      }`
                  }}
                </p>
                <p class="py-2 text-sm font-medium leading-6 not-italic inline-block" style="color: #ea5455" v-else>
                  {{
                      selectedDrugProduct.drugBookBalance >
                        selectedDrugProduct.countedQuality
                        ? `-${selectedDrugProduct.drugBookBalance -
                        selectedDrugProduct.countedQuality
                        }`
                        : `+${Math.abs(
                          selectedDrugProduct.drugBookBalance -
                          selectedDrugProduct.countedQuality
                        )}`
                  }}
                  {{
                      `${selectedDrugProduct.unit.toLowerCase() === "ml"
                        ? "ml"
                        : "u"
                      }`
                  }}
                </p>
              </div>
              <vs-divider></vs-divider>
              <p class="py-2">Notes</p>
              <div class="w-full flex flex-col increment-buttons rounded-bl rounded-big-screen pt-0 py-0"
                style="border">
                <vs-textarea id="notesHeight" :class="
                  errors.includes('notes') && isValidating
                    ? 'customTextError'
                    : ''
                " class="py-0 my-0 mb-2" name="notes" v-model="selectedDrugProduct.notes" height="150px" />
                <div v-if="errors.includes('notes') && isValidating"
                  class="con-text-validation span-text-validation-danger vs-input--text-validation-span text-xs">
                  <span class="span-text-validation">Notes are mandatory for products with variances</span>
                </div>
              </div>
            </div>
            <div class="flex flex-col items-center justify-center">
              <p @click="previousProduct" class="pb-4 cursor-pointer customNameColor text-xs font-medium">
                Previous Product
              </p>
              <button @click="nextProduct"
                class="py-4 w-full customeBorderColor rounded cursor-pointer customNameColor font-medium text-base bg-transparent fontn">
                {{
                    `Save${currentIndex + 1 != complianceDrugbook.length
                      ? ", next product"
                      : ""
                    }`
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="justify-between pb-4 px-8 hidden sm:flex">
        <button @click="$router.go(-1)" class="py-4 flex items-center gap-4 border-0 cursor-pointer" style="
            color: #0e4333;
            font-weight: 400;
            font-size: 15px;
            line-height: 17px;
            background-color: transparent;
          ">
          <vs-icon icon="arrow_back" size="small"></vs-icon>
          Go back
        </button>

        <button v-if="complianceDrugbook.length > 0" id="submitBtn"
          class="font-bold py-4 px-8 border-b-4 rounded cursor-pointer" :disabled="!isComplianceCheck"
          @click="goBackOrgOwnerCompliance" style="
            background-color: #0e4333;
            border-radius: 5px;
            font-weight: 500;
            font-size: 15px;
            line-height: 17px;
            color: #ffffff;
            border-color: transparent;
          ">
          Submit
        </button>
      </div>
      <div class="sm:hidden" v-if="
        !isStarted &&
        !complianceDrugbook.some((item) => item.fromDrugStocktake)
      ">
        <button @click="isStarted = true" class="font-bold py-4 px-8 border-b-4 rounded cursor-pointer w-full" style="
            background-color: #0e4333;
            border-radius: 5px;
            font-weight: 500;
            font-size: 15px;
            line-height: 17px;
            color: #ffffff;
            border-color: transparent;
          ">
          Start
        </button>
      </div>
      <div class="sm:hidden" v-if="
        !isStarted &&
        complianceDrugbook.some((item) => item.fromDrugStocktake)
      ">
        <button id="mobileSubmitBtn" :disabled="hasStillNoStoTake" @click="goBackOrgOwnerCompliance"
          class="font-bold py-4 px-8 border-b-4 rounded w-full cursor-pointer" style="
            background-color: #0e4333;
            border-radius: 5px;
            font-weight: 500;
            font-size: 15px;
            line-height: 17px;
            color: #ffffff;
            border-color: transparent;
          ">
          Submit
        </button>
      </div>
    </vs-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import complianceCheck from "@/views/components/org-owner/compliance/drugbook.vue";

export default {
  components: {
    complianceCheck,
  },
  data() {
    return {
      errors: [],
      quantity: 0,
      isNextProduct: false,
      notes: "",
      totalDocs: 0,
      isComplianceCheck: false,
      complianceDrugbook: [],
      hasStillNoStoTake: true,
      selectedDrugProduct: {
        unit: "",
      },
      currentIndex: 0,
      isValidating: false,
      isStarted: false,
    };
  },
  computed: {
    hasStockTake() {
      return this.complianceDrugbook.some((item) => item.fromDrugStocktake);
    },
  },
  methods: {
    ...mapActions("monthlyCompliance", [
      "fetchClinicDrugbookProducts",
      "insertStockTakeProduct",
      "updateStockTakeProduct",
    ]),
    handleClickProduct(item) {
      if (item.fromDrugStocktake) return;
      this.currentIndex = this.complianceDrugbook.findIndex(
        (currentItem) => item._id == currentItem._id
      );

      this.isStarted = true;
      // this.currentIndex = currentIndex;
      this.selectedDrugProduct = this.complianceDrugbook[this.currentIndex];
    },
    async nextProduct() {
      if (this.selectedDrugProduct.notes === "") {
        this.isValidating = true;
        this.errors.push("notes");
        this.$vs.notify({
          title: "Failed",
          text: "Notes are mandatory for products with variances.",
          color: "danger",
        });
        return;
      }
      if (this.selectedDrugProduct.countedQuality === 0) {
        this.isValidating = true;
        this.$vs.notify({
          title: "Failed",
          text: "Quantity Required",
          color: "danger",
        });
        return;
      }
      if (this.selectedDrugProduct.productName) {
        if (this.selectedDrugProduct.fromDrugStocktake) {
          this.onUpdate();
          return;
        }
        let payload = {
          complianceId: this.$route.params.complianceId,
          productId: this.selectedDrugProduct.productId,
          drugBookBalance: this.selectedDrugProduct.drugBookBalance,
          countedQuality: this.selectedDrugProduct.countedQuality,
          unit: this.selectedDrugProduct.unit,
          notes: this.selectedDrugProduct.notes,
          variance:
            this.selectedDrugProduct.drugBookBalance -
            this.selectedDrugProduct.countedQuality,
        };

        let res = await this.insertStockTakeProduct(payload);
        if (res.status != 200) return;
        this.isValidating = false;
        this.$vs.notify({
          title: "Success",
          text: "Succesfully created stocktake",
          color: "success",
        });
        this.errors = [];
        this.complianceDrugbook[this.currentIndex].fromDrugStocktake = true;
        if (this.currentIndex + 1 === this.complianceDrugbook.length) {
          this.isStarted = false;
          this.complianceDrugbook[this.currentIndex].fromDrugStocktake = true;
          this.hasStillNoStoTake = false;
          this.isComplianceCheck = true;
          return;
        }

        this.currentIndex++;
        this.selectedDrugProduct = this.complianceDrugbook[this.currentIndex];
      }
    },
    previousProduct() {
      if (this.selectedDrugProduct) {
        if (this.currentIndex == 0) {
          return;
        }

        this.currentIndex--;
        this.selectedDrugProduct = this.complianceDrugbook[this.currentIndex];
      }
    },
    addQuantity() {
      if (
        this.selectedDrugProduct.countedQuality ==
        this.selectedDrugProduct.drugBookBalance
      )
        return;

      this.selectedDrugProduct.countedQuality++;
      this.selectedDrugProduct.variance =
        this.selectedDrugProduct.drugBookBalance -
        this.selectedDrugProduct.countedQuality;
    },
    lessQuantity() {
      if (this.selectedDrugProduct.countedQuality == 0) return;
      this.selectedDrugProduct.countedQuality--;
      this.selectedDrugProduct.variance =
        this.selectedDrugProduct.drugBookBalance -
        this.selectedDrugProduct.countedQuality;
    },
    goBackOrgOwnerCompliance() {
      this.$router
        .push({
          name: "OrgOwnerComplianceStepImageUpload",
          params: {
            clinicId: this.$route.params.complianceId,
          },
        })
        .catch(() => { });
    },
    async fetchClinicDrugBookProduct() {
      try {
        let response = await this.fetchClinicDrugbookProducts({
          clinicId: this.$route.params.clinicId,
          complianceId: this.$route.params.complianceId,
        });
        this.complianceDrugbook = response.data.data;
        this.selectedDrugProduct = this.complianceDrugbook[this.currentIndex];
        this.hasStillNoStoTake = this.complianceDrugbook.some(
          (item) => !item.fromDrugStocktake
        );
      } catch (e) {
        return;
      }
    },
    async onUpdate() {
      const payload = {
        notes: this.selectedDrugProduct.notes,
        countedQuality: this.selectedDrugProduct.countedQuality,
      };
      try {
        const res = await this.updateStockTakeProduct({
          payload: payload,
          complianceId: this.$route.params.complianceId, // clinicId params is actually complianceId
          productId: this.selectedDrugProduct.productId,
        });

        if (this.currentIndex + 1 == this.complianceDrugbook.length) {
          this.isComplianceCheck = true;
          return;
        }

        this.currentIndex++;
        this.selectedDrugProduct = this.complianceDrugbook[this.currentIndex];

        this.$vs.notify({
          title: "Success",
          text: "Succesfully updated stocktake",
          color: "success",
        });
      } catch (e) {
        this.$vs.notify({
          title: "Failed",
          text: "Failed to update stocktake. Try again!",
          color: "danger",
        });
      }
    },
  },
  created() {
    this.fetchClinicDrugBookProduct();
  },
  mounted() { },
  watch: {
    selectedDrugProduct: {
      handler() {
        if (this.selectedDrugProduct.notes !== "") {
          this.errors = [];
        } else {
          this.errors.push("notes");
        }
      },
      deep: true,
    },
    "selectedDrugProduct.countedQuality"(varianceVal) {
      this.selectedDrugProduct.variance =
        this.selectedDrugProduct.drugBookBalance - varianceVal;
    },
  },
};
</script>

<style scoped lang="scss">
.customTextError {
  border: 1px solid red !important;
}

.customNameColor {
  color: #0e4333;
}

.customeBorderColor {
  border-style: solid;
  border-color: #0e4333;
}

.list-view-item {
  .increment-buttons {
    height: 42px;
    background: #3cc9b230;
    width: 100px;
  }
}

#submitBtn:disabled,
#mobileSubmitBtn:disabled {
  opacity: 0.5;
  cursor: default !important;
}

.customFlex {
  flex: 1;
}

@layer base {

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@media (min-width: 768px) {
  .title-min-height {
    min-height: 80px;
  }
}

@media (min-width: 992px) {
  .rounded-big-screen {
    border-radius: 8px;
    border-width: 1px;
  }

  .title-min-height {
    min-height: 70px;
  }
}
</style>
<style>
#card .vs-card--content {
  padding: 2px 0px;
}

#notesHeight {
  height: 100%;
}

.countedQuantity div {
  justify-content: flex-end !important;
}

#hideScrollBar .vs-con-table .vs-con-tbody {
  overflow: unset;
  min-height: 80vh;
}

.customTable .vs-table--tbody-table {
  min-width: unset;
}
</style>
